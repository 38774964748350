import { Collapse } from "antd";
import { CollapseProps } from "antd/lib/collapse";
import { MetricSetting } from "./metric-setting";
import { ReportData } from "./report-data";
import { ReportData as ReportDataV2 } from "./report-data-v2";
import { MetricTabName } from "./tab-name";
import React from "react";
import { MetricBreakDownTabsChartKeyEnum } from "@src/pages/ab-test-explore/enum/tabs-key.enum";

type MetricPerformanceItemProps = {
  targetKey: string;
};

export const MetricPerformanceItem: React.FC<MetricPerformanceItemProps> = ({ targetKey }) => {
  const collapseItems: CollapseProps["items"] = [
    {
      key: "metric",
      label: <MetricTabName targetKey={targetKey} />,
      children: <MetricSetting targetKey={targetKey} />,
    },
    // {
    //   key: MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_ACT_DATE,
    //   label: <div>Over act date</div>,
    //   children: <UserMeasureOverActDate targetKey={targetKey} chartKey={MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_ACT_DATE} />,
    //   extra: (
    //     <ChartAction tabKey={MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_ACT_DATE} tabName="Measure over act date" form={form} />
    //   ),
    // },
    // {
    //   key: MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_COHORT_DATE,
    //   label: <div>Over cohort date</div>,
    //   children: <UserMeasureOverCohortDate targetKey={targetKey} chartKey={MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_COHORT_DATE} />,
    //   extra: (
    //     <ChartAction tabKey={MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_COHORT_DATE} tabName="Measure over cohort" form={form} />
    //   ),
    // },

    // {
    //   key: MetricBreakDownTabsChartKeyEnum.USER_PERCENTILES,
    //   label: <div>User percentiles</div>,
    //   children: <UserPercentiles targetKey={targetKey} chartKey={MetricBreakDownTabsChartKeyEnum.USER_PERCENTILES} />,
    //   extra: (
    //     <ChartAction tabKey={MetricBreakDownTabsChartKeyEnum.USER_PERCENTILES} tabName="User percentiles" form={form} />
    //   ),
    // },

    // {
    //   key: MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_DAY_DIFF,
    //   label: <div>User measure over day diff</div>,
    //   children: (
    //     <UserMeasureOverDayDiff
    //       targetKey={targetKey}
    //       chartKey={MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_DAY_DIFF}
    //     />
    //   ),
    //   extra: (
    //     <ChartAction
    //       tabKey={MetricBreakDownTabsChartKeyEnum.USER_MEASURE_OVER_DAY_DIFF}
    //       tabName="User measure over day diff"
    //       form={form}
    //     />
    //   ),
    // },

    // {
    //   key: MetricBreakDownTabsChartKeyEnum.REPORT_DATA,
    //   label: <div>Report</div>,
    //   children: <ReportData targetKey={targetKey} chartKey={MetricBreakDownTabsChartKeyEnum.REPORT_DATA} />,
    // },

    {
      key: MetricBreakDownTabsChartKeyEnum.REPORT_DATA,
      label: <div>Report</div>,
      children: <ReportDataV2 targetKey={targetKey} chartKey={MetricBreakDownTabsChartKeyEnum.REPORT_DATA} />,
    },
  ];

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  return (
    <Collapse
      destroyInactivePanel
      items={collapseItems}
      defaultActiveKey={[
        "metric",
        MetricBreakDownTabsChartKeyEnum.REPORT_DATA,
        MetricBreakDownTabsChartKeyEnum.REPORT_DATA_V2,
      ]}
      onChange={onChange}
    />
  );
};
