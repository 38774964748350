import { EditOutlined } from "@ant-design/icons";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, InputNumber, InputNumberProps, Popover, Slider } from "antd";
import React from "react";

export const SignificanceLevel: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const { form, handleUpdateMetricTabItems } = useMetricPerformanceContext();

  const inputValue = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL], form);
  const onChange: InputNumberProps["onChange"] = (value) => {
    if (Number.isNaN(value)) {
      return;
    }
    const _value = Math.round(Number(value) * 100) / 100;
    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL], _value);
    handleUpdateMetricTabItems(targetKey, ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL);
  };

  return (
    <div>
      <Form.Item label="Significance level">
        <div className="flex">
          <span>{inputValue}</span>
          <Popover
            trigger="click"
            content={
              <div className="">
                <span className="text-black">Significance Level</span>
                <div className="w-[400px] flex justify-start">
                  <Slider
                    style={{ width: 300 }}
                    min={0.01}
                    max={0.2}
                    onChange={onChange}
                    value={typeof inputValue === "number" ? inputValue : 0}
                    step={0.01}
                  />
                  <InputNumber
                    min={0.01}
                    max={0.2}
                    style={{ margin: "0 16px" }}
                    step={0.01}
                    value={inputValue}
                    onChange={onChange}
                  />
                </div>
              </div>
            }
          >
            <EditOutlined className="text-blue-500 ml-2" />
          </Popover>
        </div>
      </Form.Item>
      <Form.Item name={[targetKey, ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL]} hidden>
        <InputNumber min={0.01} max={0.2} step={0.01} />
      </Form.Item>
    </div>
  );
};
