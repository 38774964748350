import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Badge, Button, Divider, Input, InputRef, Popover, Select, Space, Typography } from "antd";
import { uniqBy } from "lodash";
import React from "react";
import { FieldSelectorProps } from "react-querybuilder";

export const CustomFieldSelector = (
  props: FieldSelectorProps & {
    targetKey: string;
  },
) => {
  const inputRef = React.useRef<InputRef>(null);
  const { handleOnChange, rule } = props;

  const [newMember, setNewMember] = React.useState<string>("");
  const [options, setOptions] = React.useState<any[]>([]);

  React.useEffect(() => {
    const basicMembers: any[] = props.options.filter((option: any) => option.matched_regex === undefined);
    setOptions(
      basicMembers?.map((item) => ({
        label: item.name,
        value: item.name,
      })),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.options)]);

  const templateMember: any = props.options.filter((option: any) => option.matched_regex !== undefined)?.[0];
  const prefix = templateMember?.name?.split("_")?.[0];
  const templatedMemberWithPrefix = `${prefix + "_" + newMember}`;

  const isTemplatedMemberValid = templatedMemberWithPrefix.match(templateMember?.matched_regex);

  const handleAddNewItem = () => {
    if (!isTemplatedMemberValid) {
      return;
    }
    const tmpDef = JSON.parse(JSON.stringify({}));
    delete tmpDef["matched_regex"];
    delete tmpDef["matched"];
    delete tmpDef["matchedGroups"];

    setNewMember("");
    setOptions((prev) =>
      uniqBy([{ label: templatedMemberWithPrefix, value: templatedMemberWithPrefix }, ...prev], (item) => item.value),
    );
    handleOnChange(templatedMemberWithPrefix);
  };
  return (
    <Select
      style={{ width: 350 }}
      onChange={(val) => {
        handleOnChange(val);
      }}
      onClick={() => {
        inputRef.current?.focus();
      }}
      value={rule.field}
      options={options}
      dropdownRender={(menu) => (
        <div>
          {menu}
          {templateMember && (
            <>
              <Divider style={{ margin: "8px 0" }} />
              <Space className="flex items-center justify-between" style={{ padding: "0 8px 4px" }}>
                <Input
                  css={css`
                    .ant-input-prefix {
                      margin-right: 0px !important;
                    }
                  `}
                  ref={inputRef}
                  prefix={
                    <Typography.Text>
                      <Badge size="default" status={isTemplatedMemberValid ? "success" : "error"} text={`${prefix}_`} />
                    </Typography.Text>
                  }
                  suffix={
                    <Popover
                      content={
                        <div className="max-w-[400px]">
                          <p className="m-0">
                            Add event param ep to explore event with desired params. Input must match the template:
                          </p>
                          <span className="text-gray-500 italic">Ex: ep_difficulty_tag</span>
                        </div>
                      }
                      title="Add event param"
                      trigger="hover"
                    >
                      <ExclamationCircleOutlined />
                    </Popover>
                  }
                  value={newMember}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewMember(event.target.value);
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === "Enter") {
                      handleAddNewItem();
                    }
                  }}
                />

                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  disabled={!isTemplatedMemberValid}
                  onClick={handleAddNewItem}
                >
                  Add item
                </Button>
              </Space>
            </>
          )}
        </div>
      )}
    />
  );
};
