import React from "react";
import { Form, Input, Menu } from "antd";

// components
import ButtonDropdown from "./button-dropdown";
import TimeDateRanger from "./time-date-ranger";

// types
import { DATE_RANGES } from "@src/config/query-builder";
import { useParsed } from "@refinedev/core";
import { QueryTimeDimension } from "@src/types/cube";
import { useExploreContext } from "@src/contexts/explore-context";
import { config } from "@src/config";

type IProps = {
  member?: QueryTimeDimension;
  updateMethods: any;
};

function TimeGroupITem({ updateMethods, member: memberProps }: IProps) {
  const { pathname } = useParsed();
  const isModeView = pathname?.includes("shared-explorations");
  const runOnceTimeRender = React.useRef(true);
  const [isRangePickerVisible, setIsRangePickerVisible] = React.useState(false);
  const { timeDimensions } = useExploreContext();

  const _member: QueryTimeDimension | undefined =
    timeDimensions.find((member: QueryTimeDimension) => member.dimension.name === config.APP_REPORT_DATE) ||
    timeDimensions[0];

  const member = memberProps ?? _member;

  React.useEffect(() => {
    const isDateRange = member?.dimension.type === "time" && Array.isArray(member?.dateRange);
    if (!isRangePickerVisible && isDateRange && runOnceTimeRender.current) {
      runOnceTimeRender.current = false;
      toggleRangePicker(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  const dateRangeMenu = (onClick: any) => (
    <Menu>
      {DATE_RANGES.map((m) => (
        <Menu.Item key={m.title || m.value} onClick={() => onClick(m)}>
          {m.title || m.value}
        </Menu.Item>
      ))}
    </Menu>
  );

  function toggleRangePicker(isVisible: boolean) {
    setIsRangePickerVisible(isVisible);
  }

  function onDateRangeSelect(m: QueryTimeDimension, dateRange: [string, string]) {
    if (dateRange && !dateRange.some((d) => !d)) {
      updateMethods.update(m, {
        ...m,
        dateRange,
      });
    }
  }

  return (
    <>
      <Form.Item name="time-range" hidden>
        <Input />
      </Form.Item>
      <TimeDateRanger
        disabled={!!isModeView}
        value={Array.isArray(member?.dateRange) ? member?.dateRange : []}
        onChange={(_, dates) => {
          onDateRangeSelect(member!, dates);
        }}
      />
    </>
  );
  // return (
  //   <>
  //     <ButtonDropdown
  //       disabled={!!isModeView}
  //       overlay={dateRangeMenu((dateRange: any) => {
  //         console.log("dateRange", dateRange);

  //         form?.setFieldValue("time-range", dateRange.value);
  //         if (dateRange.value === "custom") {
  //           toggleRangePicker(true);
  //         } else {
  //           updateMethods.update(member, { ...member, dateRange: dateRange.value });
  //           toggleRangePicker(false);
  //         }
  //       })}
  //       key={`${member?.dimension.name}-date-range`}
  //       className="bg-white"
  //     >
  //       {/* {m.dateRange || "All time"} */}
  //       {isRangePickerVisible ? "Custom" : member?.dateRange || "Last 7 days"}
  //     </ButtonDropdown>
  //     <Form.Item name="time-range" hidden>
  //       <Input />
  //     </Form.Item>
  //     {isRangePickerVisible ? (
  //       <div className="inline mr-2">
  //         <TimeDateRanger
  //           disabled={!!isModeView}
  //           value={Array.isArray(member?.dateRange) ? member?.dateRange : []}
  //           onChange={(_, dates) => {
  //             onDateRangeSelect(member!, dates);
  //           }}
  //         />
  //       </div>
  //     ) : null}
  //   </>
  // );
}

export default TimeGroupITem;
