import { flattenFilters } from "@cubejs-client/core";
import { useGetIdentity, useNavigation, useOne } from "@refinedev/core";
import { config, PAGE_URL } from "@src/config";
import { mappingExpTypeToLabel } from "@src/constant/explore/mapping-title-new-explore";
import { isArrayAndNotEmpty } from "@src/util/common/array";
import { convertToDateRangeValue } from "@src/util/fb-exploration/convert-to-date-range-value";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import dayjs from "dayjs";

const validateVizState = (vizState: Record<string, any>) => {
  const result: Record<string, any> = {};
  Object.keys(vizState).forEach((key) => {
    let isMatch = isArrayAndNotEmpty(vizState[key]);
    if (key === "filters") {
      isMatch = isArrayAndNotEmpty(flattenFilters(vizState[key]));
      if (!isMatch) {
        result[key] = [{ and: [] }];
      }
    }
    if (key === "limit") isMatch = !!vizState[key];

    if (isMatch) {
      result[key] = vizState[key];
    }
  });
  return result;
};

const formatExploration = (exploration: Record<string, any>) => {
  const time =
    exploration?.time_dimensions?.find((t: any) => t.dimension === config.APP_REPORT_DATE)?.dateRange ?? "Last 7 days";

  return {
    id: exploration.id,
    name: exploration.title,
    description: exploration.description ?? "",
    productCode: exploration.product_code,
    platform: exploration.filter?.find((f: any) => f.member === config.APP_REPORT_AMA_ID)?.values ?? [],
    // "time-range": Array.isArray(time) ? "custom" : time,
    "time-range": "custom",
    "custom-time-range": Array.isArray(time) ? time?.map((t) => dayjs(t)) : convertToDateRangeValue(time),
    vizState: {
      chartType: exploration.chart_type,
      query: validateVizState({
        measures: exploration.measures,
        dimensions: exploration.dimensions,
        timeDimensions:
          exploration.time_dimensions?.length > 0
            ? exploration.time_dimensions
            : [
                {
                  dimension: config.APP_REPORT_DATE,
                  dateRange: "Last 7 days",
                },
              ],
        filters: exploration.filter,
        segments: exploration.segments,
        order: exploration.order,
      }),
      pivotConfig: exploration.pivot_config,
      type: mappingExpTypeToLabel[exploration.exp_type],
      product_code: exploration.product_code,
    },
  };
};
export const useGetExplorations = (id?: string) => {
  const { isEditMode } = getModeOfLocation();

  const { replace } = useNavigation();
  const { data: identifyOfUser } = useGetIdentity<any>();

  const {
    data: initValue,
    isLoading,
    isFetching,
  } = useOne({
    id,
    resource: `exploration/fb-exploration`,
    queryOptions: {
      staleTime: 2 * 60 * 1000,
      enabled: !!id,
      onSuccess: (data) => {
        const owner = JSON.parse(data?.data?.owner?.replace(/'/g, '"') ?? "{}");
        const isOwnerOfConfig = owner?.email === identifyOfUser?.email;

        if (isEditMode && !isOwnerOfConfig) {
          replace(PAGE_URL.ERROR_403);
        }
      },
    },
  });
  const exploration = initValue?.data ? formatExploration(initValue?.data) : null;

  return {
    exploration,
    isLoading,
    isFetching,
    owner: JSON.parse(initValue?.data?.owner?.replace(/'/g, '"') ?? "{}"),
  };
};
