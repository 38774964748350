import { ABExplorationKeyWords, SupportedMeasureType } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { IMetricEvaluateData, IRatioMeasureDefinition } from "@src/types/ab-testing-exploration";

export const getMetricEvaluationRequireMeasures = (
  measureName: string,
  measureType?: string,
  measureDefinition?: IRatioMeasureDefinition,
  includePrimaryMeasure = false,
  includeSampleSize = false,
) => {
  const requiredMeasures: string[] = [];

  if (includePrimaryMeasure) {
    requiredMeasures.push(measureName);
  }

  if (includeSampleSize) {
    requiredMeasures.push(ABExplorationKeyWords.SAMPLE_SIZE);
  }

  switch (measureType) {
    case SupportedMeasureType.PROPORTION: {
      break;
    }
    case SupportedMeasureType.RATIO: {
      if (!measureDefinition) {
        throw Error(`${SupportedMeasureType.RATIO} require measureDefinition`);
      }
      const { numerator, denominator } = measureDefinition;

      requiredMeasures.push(
        ...[
          `${numerator}_per_user`,
          `var_samp_${numerator}`,
          `${denominator}_per_user`,
          `var_samp_${denominator}`,
          `covar_samp_${measureName}`,
        ],
      );
      break;
    }
    case SupportedMeasureType.MEAN:
    default:
      requiredMeasures.push(...[`${measureName}_per_user`, `stddev_samp_${measureName}`]);
  }

  const isValidToEvaluate = (data: any): boolean => {
    return requiredMeasures.filter((k) => data[k] === null || data[k] === undefined).length === 0
    // return Object.keys(data).filter((k: any) => requiredMeasures.findIndex(k) !== -1).length = requiredMeasures.length
  }

  const toEvaluatingData = (variant: string, data: any): IMetricEvaluateData | undefined => {
    // convert to request data
    if (!isValidToEvaluate(data)) {
      return undefined
    }

    switch (measureType) {
      case SupportedMeasureType.PROPORTION: {
        return {
          exp_group: variant,
          mean: data[measureName],
          nobs: data[ABExplorationKeyWords.SAMPLE_SIZE],
        };
      }
      case SupportedMeasureType.RATIO: {
        if (!measureDefinition) {
          throw Error(`${SupportedMeasureType.RATIO} require measureDefinition`);
        }
        const { numerator, denominator } = measureDefinition;

        return {
          exp_group: variant,
          cov: data[`covar_samp_${measureName}`],
          nobs: data[ABExplorationKeyWords.SAMPLE_SIZE],
          numer: {
            mean: data[`${numerator}_per_user`],
            var: data[`var_samp_${numerator}`],
          },
          denom: {
            mean: data[`${denominator}_per_user`],
            var: data[`var_samp_${denominator}`],
          },
        };
      }
      case SupportedMeasureType.MEAN:
      default:
        return {
          exp_group: variant,
          mean: data[`${measureName}_per_user`],
          std: data[`stddev_samp_${measureName}`],
          nobs: data[ABExplorationKeyWords.SAMPLE_SIZE],
        };
    }
  };

  return {
    requiredMeasures,
    toEvaluatingData,
    isValidToEvaluate
  };
};
