import { DatabaseOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Badge, Button, Divider, Form, Input, InputRef, Modal, Popover, Select } from "antd";
import React from "react";
import { useAbTestExplorationContext } from "@src/contexts/ab-testing/ab-test-exploration-context";
import { toTitle } from "@src/helpers/text-helper";
import { css } from "@emotion/react";
import { uniq, uniqBy } from "lodash";

type BreakdownModalProps = {
  targetKey: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const MetricBreakDown: React.FC<BreakdownModalProps> = ({ targetKey, open, setOpen }) => {
  const inputRef = React.useRef<InputRef>(null);
  const { form, handleUpdateMetricTabItems } = useMetricPerformanceContext();
  const [value, setValue] = React.useState<string[] | undefined>([]);
  const [options, setOptions] = React.useState<any[]>([]);
  const [memberWithRegex, setMemberWithRegex] = React.useState<Record<string, any>>();
  const [newMember, setNewMember] = React.useState<string>("");

  const targetedModel = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_TARGET_MODEL], form);
  const { getAvailableDimensions } = useAbTestExplorationContext();
  const metricBreakdownWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], form);

  React.useEffect(() => {
    if (!metricBreakdownWatch) return;
    setValue(metricBreakdownWatch);
  }, [JSON.stringify(metricBreakdownWatch)]);

  const handleOk = () => {
    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], value);
    handleUpdateMetricTabItems(targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN);
    setOpen(false);
  };

  const prefix = memberWithRegex?.name?.split("_")?.[0];

  React.useEffect(() => {
    if (!targetedModel) return;
    const availableDimensions = getAvailableDimensions({ modelAlias: targetedModel }) || [];
    const availableCustomOptions = (form.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN]) || [])?.map(
      (item: string) => ({
        name: item,
        type: "basic",
      }),
    );

    setOptions(
      uniqBy([...availableDimensions, ...availableCustomOptions], (item) => item.name)
        .filter(({ matched_regex }) => matched_regex === undefined)
        .map(({ name, title }) => ({
          value: name,
          label: title || toTitle(name),
        })),
    );
    setMemberWithRegex(availableDimensions.filter(({ matched_regex }) => matched_regex !== undefined)?.[0]);
  }, [targetedModel, getAvailableDimensions]);

  const isMatchedRegex = `${prefix + "_" + newMember}`.match(memberWithRegex?.matched_regex);

  const handleAddItem = (e?: any) => {
    e?.stopPropagation();
    if (!isMatchedRegex) return;
    if (!newMember) return;

    const memberWithPrefix = `${prefix}_${newMember}`;
    setOptions(uniqBy([{ value: memberWithPrefix, label: memberWithPrefix }, ...options], (item) => item.value));
    setValue((prev) => uniq([memberWithPrefix, ...(prev ?? [])]).slice(0, 3));
    setNewMember("");
  };
  return (
    <div className="flex justify-center">
      <div
        className="flex items-center cursor-pointer hover:text-blue-600 text-gray-500 "
        onClick={() => {
          setOpen(true);
        }}
      >
        <DatabaseOutlined className="mr-2" />
        Breakdown ({metricBreakdownWatch?.length ?? 0})
      </div>
      <Modal open={open} onCancel={() => setOpen(false)} onOk={handleOk} width={400} title="Metric breakdown" centered>
        <Select<string[]>
          style={{ width: 350 }}
          mode="multiple"
          onChange={(value: string[]) => {
            setValue(value);
          }}
          onClick={() => {
            inputRef.current?.focus();
          }}
          maxCount={1}
          value={value}
          options={options}
          dropdownRender={(menu) => {
            return (
              <div>
                {menu}
                {memberWithRegex && (
                  <>
                    <Divider className="my-1" />
                    <div className="flex justify-between px-2 pb-1">
                      <Input
                        ref={inputRef}
                        css={css`
                          .ant-input-prefix {
                            margin-right: 0px !important;
                          }
                        `}
                        prefix={<Badge status={isMatchedRegex ? "success" : "error"} text={`${prefix}_`} />}
                        suffix={
                          <Popover
                            content={
                              <div className="max-w-[400px]">
                                <p className="m-0">
                                  Add event param (ep) to break measure with desired params dimension. Input must match
                                  the template:
                                </p>
                                <span className="text-gray-500 italic">Ex: ep_difficulty_tag</span>
                              </div>
                            }
                            title="Add event param"
                            trigger="hover"
                          >
                            <ExclamationCircleOutlined />
                          </Popover>
                        }
                        className="mr-4"
                        onChange={(e) => setNewMember(e.target.value)}
                        value={newMember}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleAddItem(e);
                            return;
                          }
                          e.stopPropagation();
                        }}
                      />
                      <Button type="primary" disabled={!isMatchedRegex} onClick={handleAddItem}>
                        <PlusOutlined />
                        Add item
                      </Button>
                    </div>
                  </>
                )}
              </div>
            );
          }}
          placeholder="Select breakdown"
        />
      </Modal>
      <Form.Item name={[targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN]} hidden>
        <Input />
      </Form.Item>
    </div>
  );
};
